
function regValue(prop, val) {
    return prop.toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
}
export default {
    props: {
        shippers: Array,
        value: String,
        handleChange: Function
    },
    data() {
        return {
            keywords: "",
            radioStyle: {
                display: 'flex',
                whiteSpace: 'normal',
                alignItems: 'center',
                margin: "10px 0px"
            },
        }
    },
    computed: {
        shipper_address_id: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        filter_shipper() {
            return this.shippers.filter(s =>
                regValue(s.name, this.keywords) ||
                regValue(s.address1, this.keywords) ||
                regValue(s.state_code, this.keywords) ||
                regValue(s.postal_code, this.keywords) ||
                regValue(s.country_code, this.keywords)
            )
        }
    }
}
